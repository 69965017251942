"use strict";
// import dataJ from "../.well-known/assetlinks.json";
import { onAuthStateChanged, signOut } from "firebase/auth";
import {
  confirmLoginModal,
  formatTime,
  initFooter,
  initLangAction,
  initLangCookie,
  list_msg,
  setDisplay,
  showConfirmModal,
  showToast,
} from "./common.js";
import { db, initAuth } from "./config.js";
import {
  child,
  off,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  onValue,
  push,
  ref,
  set,
} from "firebase/database";
import phuser from "../images/user.svg";
import phImg from "../images/img.svg";
import phSc from "../images/sc_logo_512.png";
import AgoraRTC from "agora-rtc-sdk-ng";

var btnClose, divNav, divOptions, btnMenu, btnLogout, aLogout;
var lang;
var search, action, page;
var dMain,
  dLive,
  dError,
  dLiveSearch,
  dVideoError,
  dhostVideo,
  dLiveLoader,
  dEmptyMsg,
  dMsgList;
var auth, user, userInfo;
var dbULink, dbLive, dbMsg;
let options, chanParams, live;
let agoraEngine;
let isJoined;
let dHost;
let ivHost;
let dName;
let btnFollow, btnSendMsg;
let btnMore;
let inMsg;

$(document).ready(function () {
  $(function () {
    initViews();
    setUser();
    initLangUi();
    initFooter();
    checkSize();
    initUi();
    initLs();
  });
});

/**
 * check if user logged in
 */
function setUser() {
  auth = initAuth;
  onAuthStateChanged(auth, (data) => {
    user = data;
    if (user) {
      getUserInfo();
    } else {
      resetUserUi();
    }
    setDisplay(btnLogout, user ? "flex" : "none");
    setDisplay(aLogout, user ? "flex" : "none");
  });
}
function resetUserUi() {
  let ivImg = document.querySelector("header img");
  ivImg.src = phSc;
}
/**
 * Get current logged user's information
 */
function getUserInfo() {
  let dbUser = ref(db, `user/${user.uid}`);
  onValue(
    dbUser,
    (data) => {
      if (data.exists()) {
        userInfo = data.val();
        setUserUi();
      } else {
        userInfo = null;
      }
    },
    { onlyOnce: true }
  );
}

/**
 * Set page's design based on the current logged user
 */
function setUserUi() {
  if (userInfo) {
    let ivImg = document.querySelector("header img");
    if (ivImg) {
      $(ivImg).on("error", function () {
        ivImg.src = phuser;
      });

      ivImg.src = userInfo.imgUrl ? userInfo.imgUrl : phuser;
    }
  }
}

/**
 * start language localization
 */
function initLangUi() {
  lang = initLangCookie();
  initLangAction();
}

function checkSize() {
  // if (window.innerWidth <= 800) {
  //   setDisplay(divOptions, "none");
  //   setDisplay(btnMenu, "block");
  // } else {
  //   setDisplay(divOptions, "flex");
  //   setDisplay(btnMenu, "none");
  // }
}

/**
 * Initialize views
 */
function initViews() {
  btnLogout = document.getElementById("btnLogout");
  aLogout = document.getElementById("aLogout");
  btnClose = document.getElementById("btnClose");
  divNav = document.getElementById("nav");
  divOptions = document.getElementById("divOptions");
  btnMenu = document.getElementById("btnMenu");
  dMain = document.getElementById("intro");
  dLive = document.getElementById("d-live");
  dError = document.getElementById("d-error");
  dhostVideo = dLive.querySelector(".host-video");
  dVideoError = dLive.querySelector(".error");
  dLiveLoader = dLive.querySelector(".live-loader");
  dEmptyMsg = dLive.querySelector(".empty");
  dMsgList = dLive.querySelector(".msg-list");
  dHost = document.getElementById("dHost");
  ivHost = dHost.querySelector("img");
  dName = dHost.querySelector(".name");
  btnFollow = dHost.querySelector("button.follow");
  btnMore = dHost.querySelector("button.more");
  btnSendMsg = document.getElementById("btnSendMsg");
  inMsg = document.getElementById("inMsg");

  options = {
    // Pass your App ID here.
    appId: "69e4256889774fbf80b363ae45beb9b9",
    // Set the channel name.
    channel: "",
    // Pass your temp token here.
    token: null,
    // Set the user ID.
    uid: 0,
    // Set the user role:  audience/host
    role: "audience",
  };
  chanParams = {
    // A variable to hold a local audio track.
    localAudioTrack: null,
    // A variable to hold a local video track.
    localVideoTrack: null,
    // A variable to hold a remote audio track.
    remoteAudioTrack: null,
    // A variable to hold a remote video track.
    remoteVideoTrack: null,
    // A variable to hold the remote user id.s
    remoteUid: null,
  };
}

/**
 * Set the initial display
 */
function initUi() {
  page = dMain;
  search = window.location.search;
  var d = document,
    fr = d.createElement("script");
  fr.type = "text/javascript";
  fr.async = true;
  fr.src = "https://static.fundrazr.com/widgets/loader.js";
  var s = d.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(fr, s);

  if (search) {
    action = search.substring(search.indexOf("?"), search.indexOf("="));
    let cName;
    let start = search.indexOf("=") + 1;
    if (start < search.length) {
      cName = search.substring(start).replace("+", " ");
    }

    switch (action) {
      case "?v":
        showVideoError("none", "block", 204);
        searchChannel(cName);
      case "?c":
        setPage(dLive);
        initLiveLs(cName);
        break;
      default:
        setPage(dMain);
        break;
    }
  }
}

/**
 * Set current container to show
 * @param {*} e Element to show
 */
function setPage(e) {
  setDisplay(page, "none");
  setDisplay(e, "flex");
  page = e;
}

/**
 * Find channel's key from database based on channel's name
 * @param {string} cName channel/user's name
 */
function searchChannel(cName) {
  if (!cName) {
    showVideoError("flex", "none", 233);
  }
  if (!dbULink) {
    dbULink = ref(db, `uLink/${cName}`);
  }
  onValue(
    dbULink,
    (data) => {
      if (data.exists()) {
        let chanId = data.val();
        initLiveLs(chanId);
      } else {
        showVideoError("flex", "none", 245);
        setPage(dError);
      }
    },
    { onlyOnce: true }
  );
}

/**
 * Change live video loader and error elements' display
 * @param {*} d1 Error's display: flex/none
 * @param {*} d2 loader's display: block/none
 */
function showVideoError(d1, d2, line) {
  setDisplay(dVideoError, d1);
  setDisplay(dLiveLoader, d2);
  console.log("====================================");
  console.log(`Show loader: ${line}`);
  console.log("====================================");
}

/**
 * Start listening for when a channel goes live
 * @param {*} chanId live's channel id
 * @returns if channel id is null
 */
function initLiveLs(chanId) {
  if (!chanId) {
    showVideoError("flex", "none", 273);
    return;
  }
  showVideoError("none", "block", 276);
  resetDbs();
  options.channel = chanId;
  dbLive = ref(db, `live/${chanId}`);
  let getHost = true;
  let setUi = true;
  onValue(dbLive, (data) => {
    if (data.exists()) {
      live = data.val();
      //let json = JSON.stringify(live);
      if (setUi) {
        setUi = false;
        initLiveUi();
      }
    } else {
      live = null;
      showVideoError("flex", "none", 287);
      $(btnSendMsg).off("click");
    }
    if (getHost) {
      getHost = false;
      if (user) {
        getHostInfo(chanId);
      } else {
        setHostUi(null);
      }
    }
  });
}

/**
 * Stop database listeners
 */
function resetDbs() {
  if (dbLive) {
    off(dbLive);
  }
  if (dbMsg) {
    off(dbMsg);
  }
}

/**
 * Get host information to update the live ui
 * @param {*} uid host's user id
 */
function getHostInfo(uid) {
  let dbUser = ref(db, `user/${uid}`);
  onValue(
    dbUser,
    (data) => {
      let host;
      if (data.exists()) {
        host = data.val();
        setHostUi(host);
      } else {
        showVideoError("flex", "none", 327);
        setHostUi(null);
      }
    },
    { onlyOnce: true }
  );
}

/**
 * Set or reset host ui
 * @param {*} host user information or null to reset host ui
 */
function setHostUi(host) {
  $(ivHost).on("error", function () {
    ivHost.src = phuser;
  });

  if (host) {
    ivHost.src = host.imgUrl ? host.imgUrl : phuser;
    dName.textContent = host.name;
    setDisplay(btnFollow, "block");
  } else {
    ivHost.src = phuser;
    dName.textContent = user ? "User does not exist" : "";
    //if audience is guest
    if (!user) {
      setDisplay(btnFollow, "none");
    }
  }
}
/**
 * Show dLive section
 */
function initLiveUi() {
  if (dbMsg) {
    off(dbMsg);
  }
  if (live) {
    showVideoError("none", "block", 365);
    dMsgList.textContent = "";
    dbMsg = ref(db, `msg/${options.channel}`);
    if (user) {
      getMessages();
    } else {
      setDisplay(dEmptyMsg, "block");
    }

    $(ivHost).on("error", function () {
      ivHost.src = phuser;
    });

    ivHost.src = live.img ? live.img : phuser;
    dName.textContent = live.name;
    $(btnFollow).off("click");
    $(btnFollow).on("click", function () {
      if (user) {
        //TODO: follow
        showToast("info", "Coming soon");
      } else {
        confirmLoginModal("Register or login to follow this channel", search);
      }
    });
    $(btnSendMsg).off("click");
    $(btnSendMsg).on("click", function () {
      if (user && userInfo) {
        checkInput(inMsg.value.trim());
      } else {
        let msg = user
          ? "Finish setting up your profile to participate in the chat"
          : "Register or login to participate in the chat";
        confirmLoginModal(msg, search);
      }
    });
    initAgoraEngine();
    joinChannel();
  } else {
    showVideoError("flex", "none", 399);
  }
}

function getMessages() {
  onChildAdded(dbMsg, (data) => {
    setDisplay(dEmptyMsg, "none");
    addMsg(dMsgList, data.key, data.val());
  });

  onChildChanged(dbMsg, (data) => {
    addMsg(dMsgList, data.key, data.val());
  });

  onChildRemoved(dbMsg, (data) => {
    removeElement(data.key);
    if (dMsgList.children.length == 0) {
      setDisplay(dEmptyMsg, "block");
    }
  });
}

function removeElement(id) {
  let e = document.getElementById(id);
  if (e) {
    e.remove();
  }
}
function addMsg(parent, key, msg) {
  var dOld = document.getElementById(key);
  const container = document.createElement("div");
  container.innerHTML = list_msg;
  const div = container.firstChild;
  div.setAttribute("id", key);

  let ivUser = div.querySelector("img");
  let dName = div.querySelector(".name");
  let ivImg = div.querySelector(".msg-content img");
  let dTxt = div.querySelector(".txt");
  let dDate = div.querySelector(".date");

  ivUser.src = msg.img ? msg.img : phuser;
  dName.textContent = msg.name;
  ivImg.src = msg.mediaUrl ? msg.mediaUrl : phImg;
  dTxt.textContent = msg.msg;
  dDate.textContent = formatTime(msg.date);

  if (dOld) {
    parent.replaceChild(div, dOld);
  } else {
    parent.appendChild(div);
  }
}

/**
 * Check if message input is empty or null
 * @param {*} s String input
 */
function checkInput(s) {
  if (s) {
    sendMsg(s);
  } else {
    $(inMsg).shake();
  }
}

/**
 * Send a new message
 * @param {*} msg Text input
 */
function sendMsg(msg) {
  const key = push(dbMsg).key;
  if (key) {
    const data = {
      uid: user.uid,
      name: userInfo.name,
      img: userInfo.imgUrl ? userInfo.imgUrl : null,
      chan: options.channel,
      key: key,
      type: `txt`,
      msg: msg,
      date: new Date().getTime(),
    };
    set(child(dbMsg, `${key}`), data)
      .then(() => {
        console.log("====================================");
        console.log("message sent");
        console.log("====================================");
        inMsg.value = "";
        inMsg.focus();
      })
      .catch((e) => {
        console.log("====================================");
        console.log("could not send message: " + e);
        console.log("====================================");
      });
  }
}

/**
 * Connect to a live channel
 */
async function joinChannel() {
  await agoraEngine.setClientRole(options.role);
  if (!isJoined) {
    await agoraEngine.join(
      options.appId,
      options.channel,
      options.token,
      options.uid
    );
    isJoined = true;
  }

  //if options.role == "host" create audio and video tracks and publish video
}

/**
 * Start Agora and listeners
 * @returns if agora engine is already initialized
 */
function initAgoraEngine() {
  if (agoraEngine) {
    return;
  }
  agoraEngine = AgoraRTC.createClient({ mode: "live", codec: "vp9" });
  agoraEngine.on("user-joined", async (user) => {
    console.log("====================================");
    console.log("user joined: " + user.uid);
    console.log("====================================");
  });
  agoraEngine.on("user-published", async (user, mediaType) => {
    await agoraEngine.subscribe(user, mediaType);
    if (mediaType == "video") {
      chanParams.remoteVideoTrack = user.videoTrack;
      chanParams.remoteAudioTrack = user.audioTrack;
      let uid = user.uid.toString();
      chanParams.remoteUid = uid;
      dhostVideo.id = uid;
      if (options.role != "host") {
        //play remote video
        chanParams.remoteVideoTrack.play(dhostVideo);
        showVideoError("none", "none", 541);
      }
    }

    if (mediaType == "audio") {
      chanParams.remoteAudioTrack = user.audioTrack;
      chanParams.remoteAudioTrack.play();
    }
  });

  agoraEngine.on("user-unpublished", async (user) => {
    showVideoError("flex", "none", 552);
    if (live != null && live.agUid == user.uid.toString()) {
      await agoraEngine.leave();
      isJoined = false;
    }
  });
}

/**
 * Start ui action/event listeners
 */
function initLs() {
  let aAbout = document.getElementById("aAbout");
  let dvAboutDrop = document.getElementById("dvAboutDrop");

  window.onclick = function (event) {
    let target = event.target;
    if (!target.matches(".drop-btn")) {
      if (!dvAboutDrop.classList.contains("hide")) {
        dvAboutDrop.classList.add("hide");
      }
    }
  };

  aAbout.addEventListener("click", function () {
    dvAboutDrop.classList.toggle("hide");
  });
  btnMenu.addEventListener("click", function () {
    divNav.classList.toggle("hide-block");
  });
  btnClose.addEventListener("click", function () {
    divNav.classList.toggle("hide-block");
  });

  btnLogout.addEventListener("click", function () {
    confirmLogout();
  });

  aLogout.addEventListener("click", function () {
    confirmLogout();
  });
}
function confirmLogout() {
  showConfirmModal(null, "Are you sure you want to logout?", logout);
}

function logout() {
  signOut(auth)
    .then(() => {
      showToast("success", "Signed out successfully", null);
    })
    .catch((_e) => {
      showToast("error", "Could not sign you out", null);
    });
}

/**
 * Applu shake effect on an element
 * @param {*} intShakes number of shakes: default is 4
 * @param {*} intDistance distance of shake: defaut is 2
 * @param {*} intDuration shake duration default is 500 millisenconds
 * @returns shake effect
 */
jQuery.fn.shake = function (intShakes, intDistance, intDuration) {
  intShakes = intShakes || 4;
  intDistance = intDistance || 2;
  intDuration = intDuration || 500;

  this.each(function () {
    $(this).css("position", "relative");
    for (var x = 1; x <= intShakes; x++) {
      $(this)
        .animate({ left: intDistance * -1 }, intDuration / intShakes / 4)
        .animate({ left: intDistance }, intDuration / intShakes / 2)
        .animate({ left: 0 }, intDuration / intShakes / 4);
    }
  });
  return this;
};
